// media queries
@mixin w {
  @media only screen and (max-width: "1419px") {
    @content;
  }
}

@mixin x {
  @media only screen and (max-width: "1339px") {
    @content;
  }
}

@mixin d {
  @media only screen and (max-width: "1259px") {
    @content;
  }
}

@mixin t {
  @media only screen and (max-width: "1023px") {
    @content;
  }
}

@mixin m {
  @media only screen and (max-width: "767px") {
    @content;
  }
}

@mixin a {
  @media only screen and (max-width: "639px") {
    @content;
  }
}

@mixin s {
  @media only screen and (max-width: "474px") {
    @content;
  }
}

// fonts
@mixin fi {
  font-family: "Inter", sans-serif;
}

@mixin fp {
  font-family: "Poppins", sans-serif;
}

@mixin fc {
  font-family: "Cairo";
}

$black: #11142d;
$blue: #355dff;
$blue-light: #3f8cff;
$dark: #1b1d21;
$gray: #808191;
$gray-light: #e4e4e4;
$purple: #6c5dd3;
$pink: #ff98e5;
$green: #4fbf67;
$green-dark: #3dbaa2;
$red: #ff6628;
$red-light: #ff7a68;
$orange: #ff9f38;
$white: #ffffff;
$pink: #e50c72;
// dark theme
$dark-bg: #1f2128;
$dark-border: rgba($gray-light, 0.1);
$dark-active: #23262f;
$dark-color: #242731;

$box-shadow-sm: 0 0rem 0.25rem rgba($black, 0.075);
$box-shadow: -0.25rem 0.25rem 0.75rem rgba(18, 38, 63, 0.08);
$box-shadow-lg: 0 0rem 1rem rgba($black, 0.1);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

$gradient-purple: radial-gradient(103.03% 103.03% at 0% 0%, #d080ff 0%, #6c5dd3 100%);
$gradient-green: linear-gradient(223.58deg, #86e1a0 12.52%, #4fbf67 75.73%);

@mixin h1 {
  @include fp;
  font-size: 56px;
  line-height: calc(64 / 56);
  letter-spacing: -1px;
  font-weight: 600;
}

@mixin h2 {
  @include fp;
  font-size: 48px;
  line-height: calc(56 / 48);
  letter-spacing: -1px;
  font-weight: 600;
}

@mixin h3 {
  @include fp;
  font-size: 40px;
  line-height: calc(48 / 40);
  letter-spacing: -1px;
  font-weight: 600;
}

@mixin h4 {
  @include fp;
  font-size: 32px;
  line-height: calc(40 / 32);
  letter-spacing: -0.5px;
  font-weight: 500;
}

@mixin h5 {
  @include fp;
  font-size: 24px;
  line-height: calc(32 / 24);
  font-weight: 500;
}

@mixin h6 {
  @include fp;
  font-size: 18px;
  line-height: calc(24 / 18);
  font-weight: 500;
}

@mixin title {
  font-size: 16px;
  line-height: calc(20 / 16);
  font-weight: 600;
}

@mixin caption-lg {
  font-size: 13px;
  line-height: calc(16 / 13);
  font-weight: 500;
}

@mixin caption-sm {
  font-size: 12px;
  line-height: calc(16 / 12);
  font-weight: 500;
}

@mixin caption-input {
  font-size: 10px;
  font-weight: 700;
  line-height: calc(16 / 10);
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

@mixin button-lg {
  font-weight: 700;
}

@mixin button-sm {
  font-size: 13px;
  line-height: calc(20 / 13);
  font-weight: 700;
}

@mixin chip {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
