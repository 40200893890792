.wallet-page {
  .card_wrapper {
    padding: 45px;
    @include r(1352) {
      padding: 30px;
    }
  }
}

.whiteground-card {
  position: relative;
  padding: 32px;
  border-radius: 24px;
  background: white;
  box-shadow: 5px 10px 8px #888888;
}

.wallets__flex {
  img {
    width: 40px;
    height: 40px;
    margin-left: 7px;
  }
}

.wallet-btns {
  text-align: end;
  margin: 0;
  padding: 0;

  @include r(1135) {
    text-align: center;
  }

  .btn {
    border-radius: 4px;
    padding: 0.6rem 0rem;
    margin: 10px 8px;
  }
}

.balance-item {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;

  .text-muted {
    font-weight: 600;
  }

  .balance-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include r(1318) {
      .btn:first-child {
        margin-bottom: 5px;
      }
    }

    @include r(1199) {
      justify-content: space-between;
      .btn:first-child {
        margin-bottom: 0px;
      }
    }
  }

  @include r(1200) {
    margin-bottom: 30px;
  }

  &-container {
    margin-bottom: 10px;
  }
}

.balance-price {
  color: #49c277;
  font-weight: 600;
  font-size: 1.1rem;
}

.total-balance-container {
  display: flex;
  align-items: center;

  img {
    margin-left: 25px;
    width: 40px;
    height: 40px;
  }
}

@include dark {
  .balance-item {
    border: 1px solid #2e374d;
  }
}
