.widgets {
  &__sorting {
    display: flex;

    @include r(432) {
      display: block;
    }

    @include m {
      margin: 0 -8px 24px;
    }

    .custom-dropdownitem {
      @include r(432) {
        display: flex;
        justify-content: end;
      }
    }

    .select {
      margin-top: 8px;
      margin-left: 30px;

      @include m {
        margin: 0;
      }
    }
  }

  &__select {
    @include m {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 0 8px;
    }
  }

  &__variants {
    display: flex;
    flex-wrap: wrap;
    margin: -8px auto 0 -8px;

    @include r(432) {
      justify-content: space-between;
    }

    @include m {
    }
  }

  &__variants &__link {
    cursor: pointer;
    min-width: 100px;
    margin: 8px 0 0 8px;
    padding: 0 16px;
    border-radius: 6px;
    text-align: center;
    font-weight: 600;
    line-height: 40px;
    color: $black;
    transition: all 0.2s;

    @include w {
      min-width: auto;
      padding: 0 8px;
    }

    &:hover,
    &.active {
      background-color: white;
      box-shadow: 1px 1px 10px 3px#d0d4d7;
    }

    &:hover {
      color: $myorange;
    }

    &.active {
      color: $myorange;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;

    @include m {
      display: block;
      margin: 0;
    }
  }

  &__item {
    position: relative;
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    margin: 32px 16px 0;
    border-radius: 24px;
    background: $white;
    box-shadow: 0 8px 25px rgba(#000, 0.07);

    @include m {
      max-width: 100%;
      margin: 0;

      @include nl {
        margin-bottom: 32px;
      }
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }

  &__company {
    display: flex;
    align-items: center;
  }

  &__logo {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;

    img {
      width: 100%;
      min-height: 100%;
    }
  }

  &__category {
    @include h6;
  }

  &__info {
    font-weight: 600;
    color: $gray;
  }

  &__time {
    align-self: flex-start;
    padding: 0 8px;
    border-radius: 12px;
    background: #bceaff;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 24px;
    color: $blue;
  }

  &__body {
    padding: 24px;
  }

  &__line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;
  }

  &__price {
    @include h5;
  }

  &__item &__btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 0 24px 24px;
    transition: all 0.2s;
  }

  &__item &__first_btn {
    position: absolute;
    left: 0;
    right: 50%;
    bottom: 0;
    border-radius: 0 0 0 24px;
    transition: all 0.2s;
  }

  &__item &__second_btn {
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    border-radius: 0 0 24px 0;
    transition: all 0.2s;
  }

  &__item:hover &__btn {
    visibility: visible;
    opacity: 1;
  }

  &__btns {
    margin-top: 32px;
    text-align: center;
    display: flex;
  }

  &__chart {
    height: 92px;
    margin-bottom: -30px;

    .apexcharts-canvas svg {
      overflow: visible;
    }
  }

  &__wrap {
    @include d {
      display: flex;
      flex-wrap: wrap;
      margin: -32px -24px 0;
    }

    @include t {
      margin: -24px -12px 0;
    }

    @include m {
      display: block;
      margin: 0;
    }
  }

  &__box {
    position: relative;
    padding: 24px 24px 8px;
    box-shadow: 0 8px 25px rgba(#000, 0.07);
    background: $white;
    border-radius: 24px;
    cursor: pointer;
    transition: box-shadow 0.2s;

    &:hover {
      box-shadow: inset 0 0 0 2px $blue;
    }

    @include nl {
      margin-bottom: 24px;
    }

    .status {
      display: inline-block;
    }
  }

  &__wrap &__box {
    @include d {
      flex: 0 0 calc(50% - 48px);
      width: calc(50% - 48px);
      margin: 32px 24px 0;
    }

    @include t {
      flex: 0 0 calc(50% - 24px);
      width: calc(50% - 24px);
      margin: 24px 12px 0;
    }

    @include m {
      width: 100%;
      margin: 0;
    }

    @include nl {
      @include d {
        margin-bottom: 0;
      }

      @include m {
        margin-bottom: 24px;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__box &__price {
    margin-left: -30px;
    font-size: 16px;
    font-weight: 600;
  }

  &__group {
    text-align: right;
  }

  &__favorite {
    position: absolute;
    left: 16px;
    bottom: 16px;
    z-index: 3;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: $white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16284);
    font-size: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;

    .icon {
      font-size: 14px;
      transition: fill 0.2s;
    }

    &:hover .icon {
      fill: $blue;
    }
  }

  &__box:hover &__favorite {
    visibility: visible;
    opacity: 1;
  }
}

@include dark {
  .widgets {
    &__variants .widgets__link {
      color: white;
    }

    &__variants .widgets__link:hover {
      box-shadow: 0 0 0 0 black;
      background: $dark-active;
      color: $myorange;
    }

    &__variants .widgets__link.active {
      box-shadow: 0 0 0 0 black;
      background: $dark-active;
      color: $myorange;
    }

    &__item,
    &__box {
      background: $dark-color;
    }

    &__head {
      border-color: $dark-border;
    }

    &__favorite {
      background: $dark-active;

      .icon {
        fill: $white;
      }

      &:hover .icon {
        fill: $blue;
      }
    }
  }
}
