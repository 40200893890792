/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

a {
  text-decoration: none !important;
}

label {
  margin-bottom: $form-label-margin-bottom;
  font-weight: $font-weight-medium;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid $gray-300;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-300;
  text-align: right;
}

// container

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

// row

.row {
  > * {
    position: relative;
  }
}

body[data-layout-mode="dark"] {
  // blockquote

  .blockquote {
    border-color: $gray-dark-300;
  }

  .blockquote-footer {
    color: $gray-dark-400;
  }
  a {
    color: $dark-primary;
  }
}

a {
  color: $light-primary;
}
