//
// _dropdown.scss
//
.basic-single {
  height: 100%;
}

.dropdown-menu {
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  padding: 0.25rem;

  &.show {
    top: 100% !important;
  }

  &[style] {
    right: auto !important;
    left: 0;
  }
}

.dropdown-menu-end[style] {
  left: auto !important;
  right: 0 !important;
}

.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu[data-popper-placement^="top"],
.dropdown-menu[data-popper-placement^="left"] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

.dropdown-megamenu[style] {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

.custom-dropdownitem {
  font-weight: 600;
  line-height: 40px;
  color: #000;
}

//
// Dropdown menu item color
//

@each $color, $value in $theme-colors {
  .dropdownmenu-#{$color} {
    .dropdown-item {
      &:focus,
      &:hover,
      &.active {
        background-color: rgba(($value), 0.07) !important;
        color: $value;
      }
    }
  }
}

body[data-layout-mode="dark"] {
  // Dropdown
  .dropdown {
    button {
      color: white;
    }
  }

  .dropdown-menu {
    background-color: lighten($dark-active, 2.5%);
    border-color: $gray-dark-300;
    color: $gray-dark-500;
    box-shadow: 0 0.25rem 0.75rem rgba($gray-dark-100, 0.2);
  }

  .dropdown-item {
    color: $gray-dark-500;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($gray-dark-200, 5%);
    }

    &.active,
    &:active {
      background-color: lighten($gray-dark-200, 5%);
    }
  }

  .dropdown-divider {
    border-top-color: $gray-dark-300;
  }
}
