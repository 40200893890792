.icon-activity {
  width: 0.95em;
  height: 1em;
  fill: #808191;
}
.icon-arrow-down-square {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-arrow-down {
  width: 1.5em;
  height: 1em;
  fill: #11142d;
}
.icon-arrow-right {
  width: 1.45em;
  height: 1em;
  fill: #1b1d21;
}
.icon-arrow-up-right-square {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-up-right {
  width: 1em;
  height: 1em;
  fill: #11142d;
}
.icon-award {
  width: 0.63em;
  height: 1em;
  fill: #1b1d21;
}
.icon-calendar {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-chart {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-check {
  width: 1.14em;
  height: 1em;
  fill: #11142d;
}
.icon-clock {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: #11142d;
}
.icon-copy {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-deposit {
  width: 1.06em;
  height: 1em;
  fill: #fff;
}
.icon-discount {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-document-plus {
  width: 0.89em;
  height: 1em;
  fill: #808191;
}
.icon-document-two-lines {
  width: 0.89em;
  height: 1em;
  fill: #808191;
}
.icon-document {
  width: 0.95em;
  height: 1em;
  fill: #808191;
}
.icon-dots {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}
.icon-home {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-info-circle {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-info-square {
  width: 1.07em;
  height: 1em;
  fill: #11142d;
}
.icon-link {
  width: 1.14em;
  height: 1em;
  fill: #11142d;
}
.icon-notification {
  width: 0.91em;
  height: 1em;
  fill: #808191;
}
.icon-ranking {
  width: 1.14em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #11142d;
}
.icon-settings {
  width: 0.91em;
  height: 1em;
  fill: #808191;
}
.icon-share {
  width: 1.06em;
  height: 1em;
  fill: #11142d;
}
.icon-star-fill {
  width: 1.06em;
  height: 1em;
  fill: #11142d;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-swap {
  width: 1.08em;
  height: 1em;
  fill: #808191;
}
.icon-theme-dark {
  width: 1em;
  height: 1em;
  fill: #808191;
}
.icon-theme-light {
  width: 1.04em;
  height: 1em;
  fill: #808191;
}
.icon-time-clock {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}
.icon-verify {
  width: 1.06em;
  height: 1em;
  fill: #1b1d21;
}
.icon-wallet {
  width: 1.1em;
  height: 1em;
  fill: #808191;
}
.icon-withdraw {
  width: 1em;
  height: 1em;
  fill: #fff;
}
